
































































































































































import Vue from 'vue'
import UserDropdown from '@simpl/core/components/toolbar/UserDropdown.vue'
import Headful from '@simpl/core/components/Headful'
import AppBarDeviceSelection from './AppBarDeviceSelection.vue'

import { MUTATIONS } from '../store/consts'
import { REQUEST_PREVIEW_TOKEN } from '../graphql'
import QRCode from 'qrcode'

export default Vue.extend({
  name: 'AppBarCms',

  components: {
    AppBarDeviceSelection,
    UserDropdown,
    Headful
  },

  props: {
    loggedIn: Boolean
  },

  data () {
    return {
      crumbs: [] as Record<string, any>[],

      previewToken: null! as string,
      previewUrl: null! as string,
      previewQRCode: null! as string,
      previewDialogVisible: false,

      windowClosable: window.opener != null || window.history.length === 1
    }
  },

  computed: {
    translateMode (): boolean {
      return this.$store.state.cms.translateMode
    },
    pageTitle (): string {
      return `${this.crumbs.map(c => c.text ? this.$t(c.text) : c.title).join(' / ')}`
    },
    isContentDirty (): boolean {
      return this.$store.state.cms._isDirty
    },
    isContentUpdating (): boolean {
      return this.$store.state.cms._isContentUpdating
    },
    dark: {
      get (): boolean {
        return this.$store.state.cms.dark
      },
      set (v: boolean) {
        this.$store.commit(`cms/${MUTATIONS.SET_THEME}`, v)
      }
    },
    preview: {
      get (): boolean {
        return !this.$store.state.cms.editMode
      },
      set (v: boolean) {
        if (v) {
          this.$store.commit(`cms/${MUTATIONS.SET_SIDEBAR_ACTIVE}`, 0)
        }
        this.$store.commit(`cms/${MUTATIONS.SET_EDIT_MODE}`, !v)
      }
    },
    selectedSiteId (): number {
      return this.$store.state.cms.selectedSiteId
    }
  },

  watch: {
    pageTitle: {
      handler (v) {
        this.$emit('update-page-title', v)
      },
      immediate: true
    }
  },

  methods: {
    async navigateTop () {
      if (this.crumbs.length < 2) {
        if (this.$router.currentRoute.path === '/') {
          return
        }
        await this.$router.push('/')
      } else {
        let target = null
        for (let i = 0; i < this.crumbs.length; i++) {
          if (this.crumbs[i].to) {
            target = this.crumbs[i].to
          }
        }

        await this.$router.push(target || '/')
      }
    },
    async showPreviewDialog () {
      this.previewToken = null!
      this.previewDialogVisible = true

      try {
        const result = await this.$apollo.mutate({
          mutation: REQUEST_PREVIEW_TOKEN,
          variables: {
            module_id: this.$store.state.cms.moduleId
          }
        })

        this.previewToken = result?.data?.requestPreviewToken
        /* const channel = pusher.subscribe(`private-cms-${this.previewToken}`)

        let i = 0
        setInterval(() => {
          i += 1
          channel.emit('client-test', i)
          console.log('emitted')
        }, 5000) */
      } catch (e) {
        console.warn(e)
        this.previewToken = null!
      }

      if (!this.previewToken) return

      this.previewUrl = `${window.location.origin}/cms/preview/${this.previewToken}/${this.selectedSiteId}/${this.$store.state.auth.user.languagecode}`
      this.previewQRCode = await QRCode.toDataURL(this.previewUrl, {
        errorCorrectionLevel: 'H',
        type: 'image/png',
        margin: 1,
        color: { dark: '#000', light: '#FFF' }
      })
    },
    closeWindow () {
      window.close()
    }
  }
})
