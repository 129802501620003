












































































































































































































import Vue from 'vue'
import { MUTATIONS } from '../store/consts'

const devices = require('../assets/devices/devices.json')

export default Vue.extend({
  name: 'AppBarDeviceSelection',

  data () {
    return {
      devices: devices as any,

      showDeviceMenu: false,

      searchValue: '',
      matches: [] as { device: any, showRatio: boolean }[],

      zoomOptions: [0.25, 0.5, 0.75, 1.0, 1.5, 2.0]
    }
  },

  computed: {
    deviceCategories (): string[] {
      return Object.keys(this.devices)
    },
    zoom: {
      get (): number {
        return this.$store.state.cms.zoom
      },
      set (v: number) {
        this.$store.commit(`cms/${MUTATIONS.SET_ZOOM}`, v)
      }
    },
    computedDevices (): Record<string, any> {
      const devices = {} as Record<string, any>
      this.deviceCategories.forEach(cat => {
        if (!this.devices[cat]) return

        devices[cat] = Object.keys(this.devices[cat]).map(device => {
          if (this.devices[cat][device].default) {
            this.selectDevice(this.devices[cat][device])
          }
          return this.devices[cat][device]
        })
      })

      return devices
    },
    device: {
      get (): string {
        return this.$store.state.cms.device
      },
      set (v: string) {
        this.$store.commit(`cms/${MUTATIONS.SET_DEVICE}`, v)
      }
    },
    orientation: {
      get (): string {
        return this.$store.state.cms.orientation
      },
      set (v: string) {
        this.$store.commit(`cms/${MUTATIONS.SET_ORIENTATION}`, v)
      }
    }
  },

  watch: {
    searchValue (v) {
      this.matches = []

      if (!v) return

      const searchValue = new RegExp(v, 'i')

      this.deviceCategories.forEach(category => {
        if (!this.computedDevices[category]) return

        this.computedDevices[category].forEach((device: any) => {
          const deviceName = device.name
          if (deviceName.match(searchValue) && !this.matches.find((m: any) => m.name === deviceName)) {
            this.matches.push({
              device: device,
              showRatio: category !== 'aspectRatio'
            })
          }
        })
      })
    }
  },

  methods: {
    selectDevice (device?: string) {
      this.device = device || null!
      this.showDeviceMenu = false
    }
  }
})
