import AppBarCms from '../components/AppBar.vue'

import { RouteConfig } from 'vue-router'

const eagerRoutes = [{
  path: '/cms/preview/:token/:screen/:language',
  name: 'cms.preview',
  component: () => import(/* webpackChunkName: "cms-eager-views" */'../views/CMSPreview.vue'),
  meta: {
    guest: true,
    noCookieConsent: true,
    appBarComponent: false
  },
  props: true
}] as RouteConfig[]

const routes = [{
  path: '/cms',
  name: 'cms.index',
  component: () => import(/* webpackChunkName: "cms-views" */'../views/CMSListView.vue'),
  props: true
}, {
  path: '/cms/:moduleId/:mode/:lc?',
  name: 'cms.module.edit',
  component: () => import(/* webpackChunkName: "cms-views" */'../views/CMSEditView.vue'),
  props: true,
  meta: {
    appBarComponent: AppBarCms
  }
}, {
  path: '/cms-presets',
  component: { render: (h: Function) => h('RouterView') },
  meta: {
    breadcrumbs: {
      text: 'cms.presets.headline',
      to: null
    }
  },
  children: [{
    path: ':view?',
    name: 'presets.view',
    component: () => import(/* webpackChunkName: "access-control-views" */'../views/CMSPresets.vue'),
    props: true,
    meta: {
      breadcrumbs: {
        text: 'core.action.edit',
        to: null
      }
    }
  }]
}] as RouteConfig[]

export {
  eagerRoutes,
  routes
}
